<template>
  <div>
    <AtomsTextHeading>{{ $t('Nové zboží') }}</AtomsTextHeading>
    <OrganismsListsWareList :items="newItems" type="slider" :with-slider-navigation="true" list-name="Nové zboží" class="mt-1 desktop:-mt-8 desktop:-mb-6"></OrganismsListsWareList>
  </div>
</template>
<script setup>

const locale = useLocale().getLocale();
const appConfig = useAppConfig();

const newItems = (await useApiFetch(`/api/wareList?withoutParameters=true&currencyId=${locale.currencyId}&languageId=${locale.languageId}`,
  {
    method: 'POST',
    body: {
      filter: {
        tags: [
          {
            type: 'NEW'
          }
        ]
      }
    }
  })).wareList.wares.items;

</script>